import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import "./Layout.less"

const Layout: FC = () => (
  <BaseLayout className={"default-layout"}>
    <BaseLayout className={"default-layout-content"}>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
  </BaseLayout>
)

export { Layout }
