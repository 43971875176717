import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "Outfit",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Card: {
      borderRadiusLG: 24,
      paddingLG: 48,
    },
    Button: {
      // colorPrimaryBg: "#596184",
      // defaultColor: "#596184",
    },
    Input: {
      borderRadiusLG: 11,
    },
  },
}

export default theme
